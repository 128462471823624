import moment from "moment";
import {
    GolfActive,
    GolfInactive,
    SocialActive,
    SocialInctive,
    TennisActive,
    TennisInactive
} from "../assets/png-icon";
import { GroupPeopleGray, GroupPeopleGreen } from "../assets/svg-icon";
import Config from "../config";

export const getSegmentIcon = (isSelected, title) => {
    let lowercaseTitle = title.toLowerCase();
    switch (lowercaseTitle) {
        case "golf":
            return isSelected ? GolfActive : GolfInactive;
        case "tennis":
            return isSelected ? TennisActive : TennisInactive;
        case "social":
            return isSelected ? SocialActive : SocialInctive;
        default:
            return isSelected ? GroupPeopleGreen : GroupPeopleGray;
    }
};
export const IsPatternExist = (regex, flag, value) => {
    return new RegExp(regex, flag).test(value);
};
export const getObjectByKeys = (arr, key = "Id") => {
    let obj = {};
    arr.forEach((val) => {
        obj[val[key]] = val;
    });
    return obj;
};

export const getFormattedNumber = (num) => {
    return validateNumber(num) ? formatNumber(num) : num;
};

const formatNumber = (num) => {
    num = `${num.slice(0, 2)} (${num.slice(2, 5)})-${num.slice(5, 8)}-${num.slice(8)}`;
    return num;
};
export const getFormattedNumberNoDash = (num) => {
    return validateNumber(num) ? formatNumberNoDash(num) : num;
};
const formatNumberNoDash = (num) => {
    num = `${num.slice(0, 2)} (${num.slice(2, 5)}) ${num.slice(5, 8)} ${num.slice(8)}`;
    return num;
};
const validateNumber = (num) => {
    let re = /^[+]1[0-9]{10}$/g;
    if (re.test(num)) {
        return true;
    }
    return false;
};
export function ReplaceCompanyNameInInvitationMessage(Message, CompanyName) {
    if (Message.includes("%{CompanyName}")) {
        Message = Message.replace("%{CompanyName}", CompanyName);
    }
    return Message;
}

export function ReplaceCompanyNameVariableInInvitationMessage(Message, CompanyName) {
    if (Message.includes(CompanyName)) {
        Message = Message.replace(CompanyName, "%{CompanyName}");
    }
    
    return Message;
}

export const getAgeFromDate = (date) => {
    return moment().diff(date, "years");
};

export const getScript = (ApiKey) => {
    return `<script type="text/javascript">
    (function(scope, doc, tagName, src, objectName, newElement, firstElement) {
      Array.isArray(scope["LongDriveAgency"]) ?
      scope["LongDriveAgency"].push(objectName) :
      (scope["LongDriveAgency"] = [objectName]);
      scope[objectName] =
      scope[objectName] ||
      function() {
      scope[objectName].queries = scope[objectName].queries || [];
      scope[objectName].queries.push(arguments);
      };
      scope[objectName].scriptInjectedAt = 1 * new Date();
      newElement = doc.createElement(tagName);
      newElement.setAttribute("id", "long-drive-agency-widget-script");
      firstElement = doc.getElementsByTagName(tagName)[0];
      newElement.async = 1;
      newElement.src = src;
      firstElement
      ?
      firstElement.parentNode.insertBefore(newElement, firstElement) :
      doc.getElementsByTagName("head")[0].appendChild(newElement);
      })(window, document, "script", "${
          Config.env().WIDGET_URL_DOMAIN
      }longdriveagency.js?v=1414556766776095155192326&api_key=${ApiKey}", "longDriveAgency");
      longDriveAgency("Init", "${ApiKey}");
      </script>`;
};

